import React from 'react'
import { Helmet, Link } from '../../../plugins/gatsby-plugin-react-i18next'
import Footer from '../../components/footer'
import Header from '../../components/header'
import { dispatch } from '../../state'

const PrivacyPage = () => {
  React.useEffect(() => {
    dispatch({ type: 'setHeaderInverted', value: true })
  }, [])

  return (
    <>
      <Helmet>
        <title>Privacy Policy DE</title>
        <meta name="description" content={`t('hero.intro.1') t('hero.intro.2')`} />
        <meta name="keywords" content="gatsby, react, wapps, i18next" />
      </Helmet>
      <Header />
      <div className="max-w-2xl mx-auto px-6 pt-24">
        <div className="mb-8">
          <Link to="/" className="text-teal-500">
            Zur Startseite
          </Link>
        </div>
        <h1 className="text-center text-3xl uppercase">Hinweise zum Datenschutz</h1>

        <p>
          Diese Datenschutzrichtlinie beschreibt, wie deine persönlichen Daten gesammelt, verwendet
          und weitergegeben werden, wenn du playapallet.org (die "Website") besuchst.
        </p>

        <h3 className="mt-8">WELCHE PERSÖNLICHEN INFORMATIONEN WIR SAMMELN</h3>

        <p>
          Wenn Du diese Site besuchst, verwenden wir bestimmte Informationen über dein Gerät,
          einschließlich Informationen über deinen Webbrowser und einige der Cookies, die auf deinem
          Computer installiert sind, um zu erkennen, welche Sprache dir gezeigt werden soll. Wir
          speichern die gewählte Sprache auch in deinem Browser, so dass du bei deinem nächsten
          Besuch die gleiche Sprache siehst.
        </p>

        <p>
          Wir sammeln Geräteinformationen unter Verwendung von "Cookies", das sind Datendateien, die
          auf deinem Gerät oder Computer gespeichert sind, und oft eine anonyme eindeutige Kennung
          enthalten. Für weitere Informationen über Cookies und wie man Cookies deaktiviert, besuche
          http://www.allaboutcookies.org.
        </p>

        <p>
          Wenn wir in dieser Datenschutzrichtlinie von "Persönlichen Informationen" sprechen, meinen
          wir beides: Geräteinformationen und persönliche Informationen.
        </p>

        <h3 className="mt-8">WIE VERWENDEN WIR DEINE PERSÖNLICHEN INFORMATIONEN?</h3>

        <p>
          Wir verwenden die Informationen, die wir sammeln nur, um die Dienstleistungen der Website
          anzubieten: Die Anzeige der Karte und der Sprache des Inhalts, den du siehst. Zusätzlich
          haben wir Verwenden Sie diese Informationen zur Kommunikation mit dir, wenn du ein Problem
          mit einer Palette gemeldet hast, oder mit uns zu anderen Themen in Kontakt getreten bist.
        </p>

        <h3 className="mt-8">WIE WIR DEINE PERSÖNLICHEN DATEN WEITERGEBEN</h3>

        <p>
          Wir verwenden Google Maps, um Ihnen die Standorte der Paletten und zur Bestimmung Ihrer
          Geolokalisierung. Die Datenschutzbestimmungen von Google Maps können Sie hier einsehen:
          https://policies.google.com/privacy?hl=en-US. Sie können auch in Ihrem Browser
          entscheiden, ob Sie Ihre private Geolokalisierung verwenden möchten oder nicht.
        </p>

        <p>
          Weiter können wir deine persönlichen Daten auch weitergeben, um geltende Gesetze und
          Vorschriften einzuhalten, um auf eine Vorladung, einen Durchsuchungsbefehl oder ein
          anderes rechtmäßiges Ersuchen um Informationen, einzugehen, oder um unsere Rechte
          anderweitig zu schützen.
        </p>

        <p>
          Wenn du in Europa ansässig bist, hast Sie das Recht auf Zugang zu deinen bei uns
          gespeicherten persönlichen Daten. Du hast außerdem das Recht, dass deine persönlichen
          Daten korrigiert, aktualisiert oder gelöscht werden. Wenn du von diesem Recht Gebrauch
          machen möchtest, kontaktiere uns bitte über die Kontaktinformationen unten.
        </p>

        <h3 className="mt-8">ÄNDERUNGEN</h3>

        <p>
          Wir können diese Datenschutzbestimmungen von Zeit zu Zeit aktualisieren, um z.B.
          Änderungen unserer Praktiken aufzunehmen oder aus anderen betrieblichen, rechtlichen oder
          behördlichen Gründen.
        </p>

        <h3 className="mt-8">KONTAKT US</h3>

        <p>
          Wenn du weitere Informationen über unsere Datenschutzpraktiken haben möchtest, oder wenn
          du Fragen hast oder eine Beschwerde einreichen möchtest, kontaktiere uns bitte per E-Mail
          unter info@cleanoceanproject.org oder per Mail unter Verwendung der unten angegebenen
          Details:
        </p>

        <p>Calle Los Quemados, Lajares, Kanarische Inseln, 35650, Spanien</p>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPage
